import Alpine from 'alpinejs';

import freeform from '@js/parts/alpine/freeform';
import smoothScroll from '@js/parts/alpine/smoothScroll';
import headroom from '@js/parts/alpine/headroom';
import swiper from '@js/parts/alpine/swiper';
import typewriter from '@js/parts/alpine/typewriter';
import tippy from '@js/parts/alpine/tippy';

import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
import focus from '@alpinejs/focus';

// 1st party plugins
Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(focus);


// Components
Alpine.data("freeform", freeform);
Alpine.data("smoothScroll", smoothScroll);
Alpine.data("headroom", headroom);
Alpine.data("swiper", swiper);
Alpine.data("typewriter", typewriter);
Alpine.data("tippy", tippy);

window.Alpine = Alpine;
Alpine.start();
