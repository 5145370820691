/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import spine from "./smoothScroll";
 *   Alpine.data('smoothScroll', smoothScroll);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="smoothScroll('selector')" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */
export default (selector = null) => ({
  el: null,
  selector: selector,
  init() {
    if(!this.selector) {
      return;
    }
    this.el = document.querySelector(this.selector);
    if(!this.el) {
      return;
    }
    this.$el.addEventListener('click', () => {
      this.el.scrollIntoView({
        behavior: "smooth"
      });
    });
  },
});
