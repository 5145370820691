/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import headroom from "./tooltip";
 *   Alpine.data('tooltip', tooltip);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="tooltip('{{stringVariable}}')" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */

import tippy, {sticky} from 'tippy.js';
import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css'; // optional for styling

export default (tooltipContents = '') => ({

  tooltipContents: tooltipContents,
  tooltipTarget: null,

  init() {
    let instance = tippy(this.$el, {
      content: this.tooltipContents,
      trigger: 'click',
      animation: 'scale',
      maxWidth: 175,
      sticky: true,
      plugins: [sticky],
      theme: ''
    });
  }
});
