/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import swiper from "./swiper";
 *   Alpine.data('swiper', swiper);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="...typewriter({
            autoStart:true,
            })}" />
 *
 * @returns {{init(): void, el: null, selector: null}}
 */

import Typewriter from 'typewriter-effect/dist/core';

export default (opts = {}) => ({
  options: {
    ...opts
  },
  typewriter: null,

  init() {
    if (this.typewriter !== null) {
      return;
    }
    this.typewriter = new Typewriter(this.$el, this.options);
  }
});
