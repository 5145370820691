/**
 * Usage: A simple interface to initialize headroom on an html element.
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import headroom from "./headroom";
 *   Alpine.data('headroom', headroom);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="headroom()" />
 *
 * @returns {{init(): void}}
 */

import Headroom from "headroom.js";

export default () => ({
  init() {
    const headroom = new Headroom(this.$el, {
      tolerance: {
        up: 5,
        down: 5
      }
    });
    headroom.init();
  }
});
